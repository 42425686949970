import { http } from './config'
import authHeader from './auth-header';

export default {

    lista: () => {
        return http.get('promotores', { headers: authHeader() })
    },

    listaEventos: () => {
        return http.get('eventos', { headers: authHeader() })
    },

    eventos_analise: () => {
        return http.get('eventos_analise/', {headers: authHeader() })
    },

    premiacoes_promotores: () => {
        return http.get('premiacoes_promotores', { headers: authHeader() })
    },

    listaEventosPremiacao: () => {
        return http.get('eventos_premiacao', { headers: authHeader() })
    },

    premiar: (controle_premiacao) => {
        return http.post('premiar_promotor', controle_premiacao, { headers: authHeader() })
    },

    
    informacoes_analise: (id) => {
        return http.get('informacoes_analise/'+ id,  { headers: authHeader() })
    },

    autorizar_evento: (id) => {
        return http.get('autorizar_evento/'+ id,  { headers: authHeader() })
    },

    recusar_evento: (id) => {
        return http.get('recusar_evento/'+ id,  { headers: authHeader() })
    },

    updateStatusPromotor: (id) => {
        return http.get('updateStatusPromotor/'+ id,  { headers: authHeader() })
    },
    
    updateStatusEvento: (id) => {
        return http.get('updateStatusEvento/'+ id,  { headers: authHeader() })
    },

    evento: (evento) => {
        return http.post('evento', evento, { headers: authHeader() })
    },

    status_solicitar_premiacao: (item) => {
        return http.post('status_solicitar_premiacao', item, { headers: authHeader() })
    },
    



}

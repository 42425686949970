<template>
  <v-container
    fluid
    tag="section"
  >

<v-dialog  v-model="alert_msg"  width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Mensagem
        </v-card-title>

        <v-card-text>
            {{msg_retorno}}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="alert_msg = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
</v-dialog>


<v-dialog  v-model="premiacao"  width="500">
      <v-card>
        <v-card-title class="text-h3 white--text green lighten-2">
          Premiação
        </v-card-title>

        <v-card-text>
            
             <v-container>
              <v-row>
                  <v-col cols="12">
                      <v-select  v-model="controle_premiacao.id_classificacao_pontos" item-text="evento"
            item-value="id"  Required :rules="[v => !!v || 'Campo Obrigatório']"  :items="eventos" filled  label="Evento" prepend-inner-icon="mdi-bookmark"></v-select>
                  </v-col>
              </v-row>
             </v-container>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="premiacao = false"
          >
            SAIR
          </v-btn>
           <v-btn
            color="primary"
            @click="inserirPremio()"
          >
            PREMIAR
          </v-btn>
        </v-card-actions>
      </v-card>
</v-dialog>



<base-material-card
      icon="mdi-account"
      title="Promotores Cadastrados"
      class="px-5 py-3"
    >

<v-card>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Pesquisa"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>

     <v-data-table
      :headers="header_promotres"
      :items="promotores"
      :items-per-page="24"
      :search="search"
      class="elevation-1">
  


  <template v-slot:item="row">
          <tr>
            <td style="width: 30%;font-size:14px;">{{row.item.nome}}</td>
            <td style="width: 25%;font-size:14px;">{{row.item.email}} <br> Tel: {{row.item.celular}}  SMS: {{row.item.sms}}
            <td style="width: 8%;font-size:14px;">

                    <v-chip
                          class="ma-2"
                          :color="getBadge(row.item.status)"
                          text-color="white">
                              {{getStatus(row.item.status)}}                    
                              
                    </v-chip>
            
            </td>
            <td style="width: 8%;font-size:14px;">{{row.item.saldo}}</td>

            <td style="width: 25%;font-size:14px;">
 
              <v-btn style="margin-left:10px"  v-if="row.item.status == false"  :loading="btn_status" color="warning" small @click="mudarStatus(index, row.item)">Habilitar
                    <v-icon right dark>mdi-check</v-icon>      
                </v-btn>
                <v-btn style="margin-left:10px"  v-if="row.item.status == true"  :loading="btn_status" color="warning" small @click="mudarStatus(index, row.item)">Desabilitar
                    <v-icon right dark>mdi-close</v-icon>      
                </v-btn>

                 <v-btn style="margin-left:10px"    color="success" small @click="premiar(row.item)">Premiar
                    <v-icon right dark>mdi-trophy</v-icon>      
                </v-btn>                
            </td>
          </tr>
      </template>
    </v-data-table>
</v-card>
    </base-material-card>
    
  </v-container>
</template>
<script>
  import Promotores from '../../../services/promotores'

  export default {

     created() {
          if(this.$store.state.auth.status.loggedIn == false){
           this.$router.push('/')
          }
      },

    data: () => ({
      btn_status: false,
      premiacao: false,
      dlg_permissao: false,
      loading_roles: false,
      alert_msg: false,
      alert_msg_descricao: '',
      loading: false,
      selected: [],
      promotores: [],
      eventos:[],
      header_promotres:[
          { text: 'Nome', value: 'nome' },
          { text: 'Email', value: 'email' },
          { text: 'Situação', value: 'situacao' },
          { text: 'Pontos Liberados', value: 'pontos' },

      ],


      controle_premiacao:{
        id_promotor: '',
        id_classificacao_pontos: '',
        token: '',
        evento: '',
      },
      signupRequest:{
          username: '',
          email: '',
          password: '',
          empresa: 'Previda',
      },
      msg_dialog: '',
      dialog: false,
      search: '',
      

    }),

    mounted () {
      this.listar();

    },


    methods: {
      listar () {
       this.loading = true

        Promotores.lista().then(response => {
            this.promotores = response.data
        }).catch(e => {
            if(e.response.status === 401){
            this.$store.dispatch('auth/logout');
            this.$router.push('/restrito/login');
            }

        }).finally(() => {
            this.loading = false


        })

         Promotores.listaEventosPremiacao().then(response => {
            this.eventos = response.data
        }).catch(e => {
            if(e.response.status === 401){
              this.$store.dispatch('auth/logout');
              this.$router.push('/restrito/login');
            }
             if(e.response.status === 403){
                  alert('Usuário não tem permissão')
              }

        }).finally(() => {
            this.loading = false

        })
      


      },


      clear () {       
        this.$refs.form.reset();
      },


      inserirPremio(){
        this.premiacao = false;
         Promotores.premiar(this.controle_premiacao).then(response => {
                         this.controle_premiacao = {};
                      if(response.status = 200){
                          this.alert_msg =  true;
                          this.msg_retorno = response.data.mensagem;
                      }

            this.clear();

        }).catch(e => {
                        this.btn_status = false;
                         if(e.response.status === 403){
                            alert('Usuário não tem permissão')
                          }

        }).finally(() => {
                      this.btn_status = false;

        })

      },

      premiar(item){

        this.premiacao = true;
        this.controle_premiacao.id_promotor = item.id;
        this.controle_premiacao.token = item.token;
      },

      mudarStatus(pos, item){
            this.btn_status = true;
            Promotores.updateStatusPromotor(item.id).then(response => {
                        this.promotores.indexOf(item)
                        item.status = response.data;
                      Object.assign(this.promotores[pos], item)

        }).catch(e => {
                        this.btn_status = false;

        }).finally(() => {
                      this.btn_status = false;

        })
      },

      setDisabled: function(status){
        return status
      },

      setEnable: function(status){
        return !status
      },

      getBadge (status) {
      switch (status) {
         case true: return 'green lighten-3'
        case false: return 'red lighten-2'
        default: 'success'
      }
      },

      getStatus(status) {
      switch (status) {
        case true: return 'Ativo'
        case false: return 'Inativo'
        default: ''
      }
    },

    

    }
  }

</script>
